<div class="modal-wrapper">
  <div class="modal-inner auth">
    <button class="close" (click)="close()"><img src="./assets/images/close.png" alt="close"></button>
    <ng-container [ngSwitch]="formType$ | async">
      <div class="widget" *ngSwitchCase="'widget'">
        <app-fon-bet-widget [code]="code"></app-fon-bet-widget>
      </div>
      <div class="verification" *ngSwitchCase="'verification'">
        <p class="auth__text">Code has been sent to your <br> email, enter the code you received <br> to confirm your participation.</p>
        <form class="auth__form" [formGroup]="formVerification" (ngSubmit)="onSubmit()">
        <div class="auth__row">
          <label class="auth__label"  for="name">Verification code</label>
          <input class="auth__input" [patterns]="maskPatterns" mask="NNNNN" [ngClass]="{'error': nameControl.errors}" formControlName="code" id="code" placeholder="XXXXX" type="text">
          <ng-container *ngIf="nameControl.hasError('required')">
            <div class="auth__error">Required field</div>
          </ng-container>
        </div>
        <button class="auth__button button _blue" [ngClass]="{'_disabled': formVerification.invalid}" [disabled]="formVerification.invalid" type="submit" >CONFIRM</button>
        </form>
      </div>
      <div class="registration" *ngSwitchCase="'registration'">
        <p class="auth__text">Enter your email to <br>
          participate in the raffle and <br>
          get the guaranteed prizes!</p>
        <p class="auth__subtext">We will send the notify you <br>
          to this email if you’ll win in the raffle!</p>
        <form class="auth__form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="auth__row">
            <label class="auth__label"  for="name">Name, Surname</label>
            <input class="auth__input" [ngClass]="{'error': nameControl.errors}" formControlName="name" id="name" placeholder="Kerala Blasters Fan" type="text">
            <ng-container *ngIf="nameControl.hasError('required')">
              <div class="auth__error" >Required field</div>
            </ng-container>
          </div>
          <div class="auth__row">
            <label class="auth__label"  for="email">Email</label>
            <input class="auth__input" [ngClass]="{'error': emailControl.errors}" formControlName="email" id="email" placeholder="template@mail.in" type="text">
            <ng-container *ngIf="emailControl.hasError('pattern')">
              <div class="auth__error" >Не правильный формат телефона</div>
            </ng-container>
            <ng-container *ngIf="emailControl.hasError('required')">
              <div class="auth__error" >Required field</div>
            </ng-container>
          </div>
<!--          <label class="checkbox" for="checkbox">-->
<!--            <input type="checkbox" name="color" formControlName="policy" value="red" id="checkbox" checked>-->
<!--            <span class="checkmark"></span>-->
<!--            Настоящим даю свое согласие на обработку персональных данных ООО «ФОНКОР», согласие на обработку сведений об оказанных операторами услугах связи и передачу сведений ООО «ФОНКОР», а также подтверждаю, что ознакомлен с правилами.-->
<!--          </label>-->
        <button class="auth__button button _blue" [ngClass]="{'_disabled': form.invalid}" [disabled]="form.invalid" type="submit" >PARTICIPATE</button>
        </form>
      </div>
<!--      <div class="default" *ngSwitchDefault>-->
<!--        <p class="auth__text">To receive the prize you</p>-->
<!--        <button class="auth__button button _white" (click)="formType$.next('registration')">я уже с fonbet</button>-->
<!--        <button class="auth__button button _blue" (click)="initWidget()">PARTICIPATE</button>-->
<!--      </div>-->
    </ng-container>
  </div>
</div>
