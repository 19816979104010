import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {GameService} from "../../../services/game.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-congratulation-modal',
	standalone: true,
	imports: [],
	templateUrl: './congratulation-modal.component.html',
	styleUrl: './congratulation-modal.component.scss',
})
export class CongratulationModalComponent implements OnInit {
	@Output() closeModal = new EventEmitter<void>();

	constructor(private game: GameService, private router: Router) {}
	ngOnInit() {
		setTimeout(() => {
      this.router.navigate(['/awards']);
      this.game.levelState$ = 'start';
      this.game.currentLevel$.next(1);
			this.closeModal.emit();
		}, 4000);
	}
}
